<template>
  <div class="view-container">
    <header-component
      v-if="!getIsExternalAuth"
      :logoPath="logoPath"
    />

    <alert-card-component
      v-if="showAlertCard"
      class="alert-card-component"
      :data="alertCardData"
      @hideCard="hideAlertCard"
    />

    <slot name="content"/>

    <footer-component
      v-if="!(hideFooter || getIsExternalAuth)"
      :companyName="companyName"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Header from '@/components/base/Header';
  import Footer from '@/components/base/Footer';
  import AlertCard from '@/components/AlertCard';

  export default {
    name: 'DefaultView',

    data() {
      return {
        logoPath: 'logo-white.png',
        companyName: 'Instituto Mix de Profissões'
      };
    },

    computed: {
      ...mapGetters({
        getIsExternalAuth: 'authModule/getIsExternalAuth'
      })
    },

    props: {
      showAlertCard: {
        type: Boolean,
        default: false
      },

      hideFooter: {
        type: Boolean,
        default: false
      },

      alertCardData: {
        type: Object,
        default() {
          return {
            text: 'Aconteceu um erro. Tente novamente!',
            color: '#FF5A5A'
          };
        }
      },
    },

    components: {
      'header-component': Header,
      'footer-component': Footer,
      'alert-card-component': AlertCard,
    },

    methods: {
      hideAlertCard() {
        this.$emit('hideAlertCard');
      }
    },

    mounted() {
      this.companyName = this.$service.getPlatformName();

      const platform = this.$service.getPlatform();
      if (platform == 'grad') {
        this.logoPath = 'logo-uni-white.png';
      } else if (platform == 'aprovat') {
        this.logoPath = 'logo-aprovat.png';
      }
    }
  };
</script>

<style lang="scss" scoped>
  .view-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .alert-card-component {
      margin-top: 6rem;
      position: fixed;
    }
  }
</style>