<template>
  <default-view-component
    :showAlertCard="showAlertCard"
    :alertCardData="alertCardData"
  >
    <template v-slot:content>
      <div class="content">
        <spinner-component
          v-if="hasRequest"
          class="spinner-component"
          :styles="spinner"
        />

        <div v-else class="term-container">
          <div v-if="$service.getPlatform() == 'grad'">
            <h1 class="title">TERMOS E CONDIÇÕES PARA AQUISIÇÃO E USO: GRADUAÇÃO EAD</h1>

            <unim-term-component />
          </div>

          <div v-else>
            <h1 class="title">TERMOS E CONDIÇÕES PARA AQUISIÇÃO E USO</h1>

            <default-term-component />
          </div>

          <div class="button-container">
            <button-component
              class="button-component"
              v-ripple="'rgba(255, 255, 255, 0.2)'"
              buttonTextColor="#FFFFFF"
              buttonHoverColor="rgba(255, 255, 255, 0.1)"
              text="Li e aceito os termos"
              :hasLoader="hasCreateCheckoutRequest"
              :isDisabled="hasCreateCheckoutRequest"
              @click.native="submit"
            />
          </div>
        </div>
      </div>
    </template>
  </default-view-component>
</template>

<script>
  import DefaultView from '@/components/base/DefaultView';
  import Spinner from '@/components/Spinner';
  import Button from '@/components/Button';
  import Default from '@/components/term/Default';
  import Unim from '@/components/term/Unim';

  export default {
    name: 'AcceptTermsPage',

    data() {
      return {
        hasRequest: false,
        hasCreateCheckoutRequest: false,

        showAlertCard: false,
        alertCardData: {},

        contractId: '',

        spinner: {
          size: '4px',
          color: '#FEAA3B',
          borderSize: '1em',
        },
      };
    },

    components: {
      'default-view-component': DefaultView,
      'spinner-component': Spinner,
      'button-component': Button,
      'default-term-component': Default,
      'unim-term-component': Unim,
    },

    created() {
      if (this.$route.params.contractId) {
        this.contractId = this.$route.params.contractId;
        this.fetchContract(this.contractId);
      }
    },

    methods: {
      async fetchContract(contractId) {
        this.hasRequest = true;

        await this.$store.dispatch('getContract', contractId)
          .then(res => {
            if (res.data.is_checkout_fd == "0") {
              this.toContractAction();
            }

            if (res.data.is_agreement_accepted == "1" && !res.data.id_checkout) {
              this.createCheckout(contractId);
            }

            if (res.data.id_checkout) {
              this.toCheckout();
            }
          })
          .catch(() => {
            this.alertCardData = {
              text: 'Erro ao buscar contrato',
              color: '#FF5A5A'
            };

            this.showAlertCard = true;

            this.$router.push('/precontract/new');
          });

        this.hasRequest = false;
      },

      async createCheckout(contractId) {
        await this.$store.dispatch('postCreateCheckout', contractId)
          .then(() => {
            this.toCheckout();
          })
          .catch(() => {
            this.alertCardData = {
              text: 'Erro ao criar link de pagamento',
              color: '#FF5A5A'
            };

            this.showAlertCard = true;
          });
      },

      async acceptAgreement() {
        const data = {
          contract_id: this.contractId,
        };

        await this.$store.dispatch('updateTermsOfAgreement', data)
          .catch(() => {
            this.alertCardData = {
              text: 'Erro ao aceitar os termos',
              color: '#FF5A5A'
            };

            this.showAlertCard = true;
          });
      },

      toCheckout() {
        this.$router.push(`/precontract/${this.contractId}/checkout/pay`);
      },

      toContractAction() {
        this.$router.push(`/precontract/${this.contractId}`);
      },

      async submit() {
        this.hasCreateCheckoutRequest = true;

        await this.acceptAgreement();
        await this.createCheckout(this.contractId);

        this.hasCreateCheckoutRequest = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .spinner-component {
      position: absolute;
      left: 0;
      right: 0;
      top: 45%;
      margin-left: auto;
      margin-right: auto;
    }

    .term-container {
      max-width: 768px;
      padding: 1rem;
      width: 100%;

      .title {
        font-size: 20px;
        margin-bottom: 0.5rem;
        text-align: center;
      }

      .term-content {
        overflow-y: auto;
        height: 55vh;

        .section {
          margin-top: 1rem;
          padding: 0 1rem;

          .sub {
            margin-left: 1.5rem;
          }

          .subtitle {
            font-size: 16px;
            margin-bottom: 0.5rem;
          }

          a {
            color: #3182CE;
          }

          p {
            text-align: justify;
            font-size: 12px;
            margin-bottom: 0.5rem;
          }

          ul {
            margin-left: 2.5rem;
          }

          table {
            width: 100%;
            font-size: 10px;
            margin-top: 1rem;
            margin-bottom: 1rem;
            border-collapse: collapse;
          }

          thead th {
            background-color: #D9D9D9;
            padding: 8px;
            text-align: left;
          }

          tbody td {
            padding: 8px;
            border: 1px solid #000;
            text-align: center;
          }
        }
      }

      .button-container {
        margin-top: 1.5rem;

        .button-component {
          max-width: 200px;
          margin-left: auto;
        }
      }
    }
  }
</style>