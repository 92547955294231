<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'App',

    metaInfo() {
      return {
        link: [
          {
            rel: 'icon',
            href: this.getFaviconPath()
          }
        ]
      };
    },

    mounted() {
      if (process.env.NODE_ENV === 'production') {
        this.loadClarityScript();
      } else {
        console.log(`Environment: %c${process.env.NODE_ENV}`, 'color: red;');
      }

      const platform = this.$service.getPlatform();
      if (platform == 'grad') {
        document.documentElement.style.setProperty('--main-color', '#CB0000');
        document.documentElement.style.setProperty('--second-color', '#61CF70');
        document.documentElement.style.setProperty('--second-color-dark', '#32B044');
        document.documentElement.style.setProperty('--second-color-light', '#94D09C');
      } else if (platform == 'aprovat') {
        document.documentElement.style.setProperty('--main-color', '#DBDFE3');
        document.documentElement.style.setProperty('--second-color', '#76B70F');
        document.documentElement.style.setProperty('--second-color-dark', '#528108');
        document.documentElement.style.setProperty('--second-color-light', '#98CF40');
        document.documentElement.style.setProperty('--font-color', '#57697A');
      }
    },

    methods: {
      loadClarityScript() {
        if (process.env.VUE_APP_CLARITY_ID) {
          const scriptContent = `
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);
                t.async=1;
                t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];
                y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${process.env.VUE_APP_CLARITY_ID}");
          `;

          let script = document.createElement('script');
          script.textContent = scriptContent;
          document.head.appendChild(script);
        }
      },

      getFaviconPath() {
        const platform = this.$service.getPlatform();
        if (platform == 'grad') {
          return '/favicon-unim.ico';
        } else if (platform == 'aprovat') {
          return '/favicon-aprovat.ico';
        }

        return '/favicon.ico';
      }
    }
  };
</script>

<style lang="scss">
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    letter-spacing: 0.2px;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
    height: 100%;
  }

  body, input, select, button {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-style: normal;
  }

  body {
    min-width: 320px;
    padding: 0;
    overflow-x: hidden;
    height: 100%;
  }

  #app {
    height: 100%;
  }

  button {
    line-height: 100%;
    font-weight: 600;
    font-size: 17px;
  }

  img {
    -webkit-user-drag: none;
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
  }

  h4 {
    font-size: 14px;
    font-weight: 600;
  }

  p {
    font-weight: 300;
    font-size: 14px;
  }
</style>