<template>
  <footer>
    © Copyright {{ currentFullYear }} {{ companyName }} - Todos os direitos reservados.
  </footer>
</template>

<script>
  export default {
    name: 'FooterComponent',

    data() {
      return {
        currentFullYear: '',
        companyName: 'Instituto Mix de Profissões'
      };
    },

    mounted() {
      const date = new Date();
      this.currentFullYear = date.getFullYear();

      this.companyName = this.$service.getPlatformName();
    },
  };
</script>

<style lang="scss" scoped>
  footer {
    background: $main-color;
    min-width: 320px;
    width: 100%;
    color: $font-color;
    font-size: 14px;
    padding: 0.5rem 1.5rem;
    margin-top: auto;
    z-index: 1;
    display: flex;
    align-items: center;

    img {
      width: 105px;
      margin-left: auto;
    }
  }

  @media (max-width: 820px) {
    footer {
      font-size: 12px;
      flex-direction: column-reverse;
      text-align: center;

      img {
        margin-left: unset;
        margin-bottom: 0.5rem;
      }
    }
  }

  @media (max-width: 520px) {
    footer {
      font-size: 12px;
      padding: 0.5rem;
    }
  }
</style>